<template>
  <div>
    <b-row
      align-h="center"
      class="mt-3"
    >
      <b-col lg="8">
        <b-card header="Edit Role Permissions">
          <b-form>
            <select-permissions
              :permissions="formattedGroupedPermissions"
              @assignPermission="assignRolePermission"
              @revokePermission="revokeRolePermission"
              @assignPermissionsGroup="assignPermissionsGroup"
            />
            <back />
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import SelectPermissions from '@/common/components/Permissions/PermissionsTableSelect.vue'

import handleAlerts from '@/common/compositions/common/handleAlerts'
import checkUserIsAdmin from '@/common/compositions/common/checkUserIsAdmin'
import allPermissionsComposition from '@/common/compositions/permissions/getAllPermissions'
import userPermissionsInEntity from '@/common/compositions/permissions/getUserPermissionsInEntity'
import rolePermissions from '@/common/compositions/permissions/getRolePermissions'
import formatPermissions from '@/common/compositions/permissions/formatGroupedPermissions'
import Back from '@/common/components/common/Back.vue'

export default {
  name: 'EditRolePermissions',
  components: {
    SelectPermissions,
    Back,
  },
  computed: {
    roleId: {
      get() {
        return this.$router.currentRoute.params.roleId
      },
    },
    entityId: {
      get() {
        return this.$router.currentRoute.params.entityId
      },
    },
  },
  created() {
    this.getRolePermissionsAndAvailablePermissions()
  },
  setup() {
    const { isUserAdminPromise } = checkUserIsAdmin()

    const {
      formatGroupedPermissions,
      formattedGroupedPermissions,
    } = formatPermissions()

    const { successfulOperationAlert, showErrors } = handleAlerts()

    const {
      getAllPermissions,
    } = allPermissionsComposition()

    const {
      getUserPermissionsInEntity,
    } = userPermissionsInEntity()

    const { getRolePermissions } = rolePermissions()

    return {
      successfulOperationAlert,
      showErrors,
      getAllPermissions,
      getUserPermissionsInEntity,
      getRolePermissions,
      formatGroupedPermissions,
      formattedGroupedPermissions,
      isUserAdminPromise,
    }
  },
  methods: {
    getRolePermissionsAndAvailablePermissions() {
      const rolePermissionsPromise = this.getRolePermissions(this.roleId)

      this.isUserAdminPromise().then(() => {
        const allPermissionsPromise = this.getAllPermissions()
        this.resolvePermissionsPromises(allPermissionsPromise, rolePermissionsPromise)
      }).catch(() => {
        const allPermissionsPromise = this.getUserPermissionsInEntity(
          this.$store.getters['auth/getUserData'].username,
          this.entityId,
        )
        this.resolvePermissionsPromises(allPermissionsPromise, rolePermissionsPromise)
      })
    },
    resolvePermissionsPromises(allPermissionsPromise, rolePermissionsPromise) {
      Promise.all([allPermissionsPromise, rolePermissionsPromise]).then(res => {
        const allPermissions = res[0]
        const userPermissions = res[1]

        this.markRolePermissionsAsMarked(allPermissions, userPermissions)

        this.formatGroupedPermissions(allPermissions)
      })
    },
    markRolePermissionsAsMarked(allPermissions, userPermissions) {
      Object.entries(userPermissions).forEach(([permissionsGroupName, permissions]) => {
        permissions.forEach(permission => {
          const permissionIndex = allPermissions[permissionsGroupName].findIndex(ele => ele.id === permission.id)
          if (allPermissions[permissionsGroupName][permissionIndex] !== undefined) {
            allPermissions[permissionsGroupName][permissionIndex].vgtSelected = true
          }
        })
      })
    },
    assignRolePermission(permissionId) {
      this.$portalUsers.post(`v1/role-assign-permission/${this.roleId}/${permissionId}`, {
        entity: this.entityId,
      }).then(() => {
        this.successfulOperationAlert('Permission is assigned to role successfully')
      }).catch(err => {
        this.showErrors(err.response.data.errors)
      })
    },
    revokeRolePermission(permissionId) {
      this.$portalUsers.post(`v1/role-revoke-permission/${this.roleId}/${permissionId}`, {
        entity: this.entityId,
      }).then(() => {
        this.successfulOperationAlert('Permission is revoked from role successfully')
      }).catch(err => {
        this.showErrors(err.response.data.errors)
      })
    },
    assignPermissionsGroup(permissionsId) {
      this.$portalUsers.post(`v1/role-assign-permissions/${this.roleId}`, {
        entity: this.entityId,
        permissions: permissionsId,
      }).then(() => {
        this.successfulOperationAlert('Permissions is assigned to role successfully')
      }).catch(err => {
        this.showErrors(err.response.data.errors)
      })
    },
  },
}
</script>
<style lang="scss">
</style>
