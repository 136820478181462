import axiosInstances from '@/libs/axios-instances'

export default function rolePermissions() {
  const getRolePermissions = roleId => axiosInstances.portalUsers.get(`v1/role-permissions/${roleId}`, {
    params: {
      formatted: 1,
    },
  }).then(res => {
    const { permissions } = res.data.data
    return permissions
  })

  return {
    getRolePermissions,
  }
}
